<template>
<v-container grid-list-xl fluid>
    <v-layout row wrap>
        <v-flex lg12>
            <v-card>
                <hpo-filter :headers="headers" :viewName="viewName" v-on:applyFilter="applyFilter" v-on:quickFilter="quickFilter"></hpo-filter>
                <v-data-table fixed-header height="750px"   :headers="headers" :items="items" :options.sync="pagination" :server-items-length="total_rows" :footer-props="items_per_page" :must-sort="true" :loading="loading" :mobile-breakpoint="100">
                    <template v-slot:header="{ props }"><table-header-filter :headers="props.headers" :viewName="viewName" v-on:applyFilter="applyFilter"></table-header-filter></template>
                    <template v-slot:item="{ item, index }">
                        <tr :id="'r-' + item.ID">
                            <td>{{ total_rows - index}}</td>
                            <td>{{ item.ID }}</td>
                            <td>{{ item.Username }}</td>
                            <td class="text-end">{{ item.NumberOfPack | currency }}</td>
                            <td class="text-end">{{ item.Weight | currency('', 2) }}</td>
                            <td class="text-end">{{ item.Volume | currency('', 3) }}</td>
                            <td class="text-end">{{ item.FeeShipVND | currency }}</td>
                            <td class="text-end">{{ item.TotalAmount | currency }}</td>
                            <td v-bind:style="{ 'color': get_status_color(item.Status, receipt_status)}">
                                {{ item.Status | display_value(receipt_status) }}
                                <v-icon small class="ma-1" color="green" v-if="!item.LockedReceipt">fa-sms</v-icon>
                            </td>
                            <td>{{ item.CreatedDate | display_date('DD-MM-YYYY')}} <br /> {{ item.CreatedDate | display_date('HH:mm:ss')}} </td>
                            <td>{{ item.ExportDate | display_date('DD-MM-YYYY')}} <br /> {{ item.ExportDate | display_date('HH:mm:ss')}} </td>
                            <td>{{ item.Note }}</td>
                            <td class="text-center">
                                <v-icon medium class="ma-1" @click="gotoDetail(item)">fa-edit</v-icon>
                                <v-icon medium class="ma-1" @click="deleteItem(item.ID)" :disabled="!adminRole">fa-trash-alt</v-icon>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:body.append v-if="summary">
                        <tr class="total-summary-td">
                            <td colspan="3" class="text-center font-weight-bold">Tổng</td>
                            <td class="text-end font-weight-bold">{{ summary.NumberOfPack | currency}}</td>
                            <td class="text-end font-weight-bold">{{ summary.Weight | currency('', 2) }}</td>
                            <td class="text-end font-weight-bold">{{ summary.Volume | currency('', 3) }}</td>
                            <td class="text-end font-weight-bold">{{ summary.FeeShipVND | currency }}</td>
                            <td class="text-end font-weight-bold">{{ summary.TotalAmount | currency}}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card>
        </v-flex>
    </v-layout>
    <v-dialog v-model="dialogReceipt" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="oee-theme">
            <v-btn icon dark @click="dialogReceipt = false">
              <v-icon>fa-times-circle</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-toolbar-title>Phiếu xuất kho #{{selectedItem.ID > 0 ? selectedItem.ID : 'Tạo mới'}}</v-toolbar-title>
        </v-toolbar>
        <v-card-actions>
            <div class="pl-4 red--text" v-if="!isApprovePayment">Khách hàng chưa được duyệt công nợ!</div>  
            <v-spacer></v-spacer>
            <v-btn color="red darken-1 white--text"  class="mt-8 mr-2" @click="printDetail" :disabled="!isApprovePayment">In phiếu</v-btn>
            <v-btn color="green darken-1 white--text"  class="mt-8 mr-2" v-if="selectedItem.Status == 0 && selectedItem.ID > 0 && selectedItem.LockedReceipt" @click="exportItem()" :disabled="!isApprovePayment">Xuất kho</v-btn>
            <v-btn color="blue darken-1 white--text"  class="mt-8 mr-4" @click="lockReceipt" v-if="!selectedItem.LockedReceipt">Chốt phiếu & thông báo</v-btn>
            <v-btn color="blue darken-1 white--text"  class="mt-8 mr-4" @click="saveItem">Lưu lại</v-btn>
        </v-card-actions>
        <v-card-text>
          <v-container grid-list-xl fluid>
            <v-layout row wrap>
                <v-flex lg4 md4 sm12 pa-0 pl-3>
                    <v-autocomplete :rules="requireRules" :readonly="selectedItem.ID > 0" label="Khách hàng" v-model="selectedItem.UID" :items="customers" item-text="FilterName" item-value="ID" @change="onSelectCustomer" :disabled="selectedItem.LockedReceipt"></v-autocomplete>
                </v-flex>
                <v-flex lg4 md4 sm12 pa-0 pl-2>
                    <v-autocomplete label="Địa chỉ nhận" v-model="selectedAddressID" :items="addressBooks" item-text="Receiver" item-value="ID" @change="onSelectAddress"></v-autocomplete>
                </v-flex>
            </v-layout>
            <v-layout row wrap>
                <v-flex lg4 md4 sm12 pa-0 pl-3>
                    <v-autocomplete readonly label="Chăm sóc khách hàng" v-model="selectedCSID" :items="staffs" item-text="FullName" item-value="ID"></v-autocomplete>
                </v-flex>
                <v-flex lg4 md4 sm12 pa-0 pl-2 pt-8 v-if="selectedCSPhone">
                    Liên hệ Zalo: <a target="blank" :href="'https://zalo.me/' + selectedCSPhone">{{ selectedCSPhone }}</a>
                </v-flex>
            </v-layout>
            <v-layout row wrap>
                <v-flex lg6 md6 sm12 v-if="selectedItem.Status == 0">
                    <v-text-field label="Mã bill" ref="trancode" v-model="newTransactionCode" @keydown.enter="appendPackageItem(false)" :disabled="!selectedItem.UID || !selectedAddressID || (selectedItem.Status == 1 && selectedItem.ID > 0)"></v-text-field>
                </v-flex>
                <v-flex lg6 md6 sm12 v-if="selectedItem.Status == 0">
                    <v-text-field label="Mã bao hàng" ref="bigcode" v-model="bigpackageCode" @keydown.enter="appendBigPackage()" :disabled="!selectedItem.UID || !selectedAddressID || (selectedItem.Status == 1 && selectedItem.ID > 0)"></v-text-field>
                </v-flex>
                <v-flex lg12 md12 sm12 xs12>
                    <span style="color: red">{{internalMessage}}</span>
                </v-flex>
                <v-flex lg12 pt-0>
                    <v-card>
                        <v-data-table fixed-header height="450px" :headers="package_headers" :items="orderTransactions" :options.sync="package_pagination" :server-items-length="orderTransactions ? orderTransactions.length : 0" :footer-props="{'items-per-page-options': [-1]}" :must-sort="true" :mobile-breakpoint="100">
                            <template v-slot:item="{ item, index }">
                                <tr :id="'r-' + item.ID">
                                    <td>{{ (orderTransactions ? orderTransactions.length : 0) - index }}</td>
                                    <td>{{ item.TransactionCode }}</td>
                                    <td>{{ item.ProductType | display_value(product_type_list) }}</td>
                                    <td class="text-end">{{ item.Weight | currency('', 2) }}</td>
                                    <td class="text-end">{{ item.Volume | currency('', 3) }}</td>
                                    <td class="text-end">{{ item.RealNumberOfPack | currency }}</td>
                                    <td class="text-end">{{ item.TotalWeight | currency('', 2) }}</td>
                                    <td class="text-end">{{ item.TotalVolume | currency('', 3) }}</td>
                                    <td class="text-right">{{ item.FeeShipVND | currency }}</td>
                                    <td v-bind:style="{ 'color': get_status_color(item.Status, package_status)}">{{ item.Status | display_value(package_status) }}</td>
                                    <td>{{ item.OrderNote }}</td>
                                    <td class="text-center">
                                        <v-icon medium @click="deletePackageItem(item)" :disabled="selectedItem.Status == 1 && selectedItem.ID > 0 && !adminRole">fa-trash-alt</v-icon>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex lg3 md3 sm12>
                <v-select :rules="requireRules" label="Kho VN" v-model="selectedItem.VNStoreID" :items="vnstores" item-text="Name" item-value="ID"></v-select>
              </v-flex>
              <v-flex lg3 md3 sm12>
                <h-currency-input v-model="selectedItem.NumberOfPack" label="Số kiện" :decimal="0" readonly></h-currency-input>
              </v-flex>
              <v-flex lg3 md3 sm12>
                <h-currency-input v-model="selectedItem.FeeShipVND" label="Phí khác" :decimal="0" readonly></h-currency-input>
              </v-flex>
              <v-flex lg3 md3 sm12>
                <h-currency-input v-model="selectedItem.TotalAmount" label="Tổng tiền" :decimal="0" readonly></h-currency-input>
              </v-flex>
              <v-flex lg3 md3 sm12>
                <v-text-field label="Hình thức ship" v-model="selectedItem.ShippingMethod"></v-text-field>
              </v-flex>
              <v-flex lg3 md3 sm12>
                <v-text-field label="Người nhận" v-model="selectedItem.Receiver"></v-text-field>
              </v-flex>
              <v-flex lg3 md3 sm12>
                <v-text-field label="Điện thoại" v-model="selectedItem.Phone"></v-text-field>
              </v-flex>
              <v-flex lg3 md3 sm12>
                <v-text-field label="Địa chỉ" v-model="selectedItem.Address"></v-text-field>
              </v-flex>
              <v-flex lg12 md12 sm12 xs12>
                <v-text-field label="Đơn vị vận chuyển" v-model="selectedItem.Shipper"></v-text-field>
              </v-flex>
              <v-flex lg12 md12 sm12 xs12>
                <v-text-field label="Ghi chú nội bộ" v-model="selectedItem.Note"></v-text-field>
              </v-flex>
              <v-flex lg12 md12 sm12 xs12>
                <v-text-field label="Ghi chú khách hàng" v-model="selectedItem.NoteForCustomer"></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import { mapState } from "vuex";
import configs from "@/configs";
import Filtering from "@/components/Filtering";
import { sendErrorNotice, sendSuccessNotice, refreshResource, isMobileAgent, playSound } from '@/commons/utils';
import { request_list, print_export_receipt_order, print_export_receipt_deposit } from '@/commons/hpod';
import Vue from 'vue';
import _ from "lodash";

export default {
    components: {
        "hpo-filter": Filtering
    },
    props: ["uId"],
    data() {
        return {
            headers: [
                {
                    text: "STT",
                    value: "index"
                },
                {
                    text: "Mã phiếu",
                    value: "ID",
                    filterable: true,
                    quickSearch: true,
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Khách hàng",
                    value: "Username",
                    filterable: true,
                    quickSearch: true,
                    dataType: configs.DATA_TYPE["String"]
                },
                {
                    text: "Số kiện",
                    value: "NumberOfPack",
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Tổng cân",
                    value: "Weight",
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Tổng khối",
                    value: "Volume",
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Phí khác",
                    value: "FeeShipVND",
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Tổng tiền",
                    value: "TotalAmount",
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Trạng thái",
                    value: "Status",
                    filterable: true,
                    values: configs.EXPORT_RECEIPT_STATUS,
                    dataType: configs.DATA_TYPE["Enum"]
                },
                {
                    text: "Ngày tạo",
                    value: "CreatedDate",
                    filterable: true,
                    dataType: configs.DATA_TYPE["Date"]
                },
                {
                    text: "Ngày xuất",
                    value: "ExportDate",
                    filterable: true,
                    dataType: configs.DATA_TYPE["Date"]
                },
                {
                    text: "Ghi chú",
                    value: "Note",
                    filterable: true,
                    quickSearch: true,
                    dataType: configs.DATA_TYPE["String"]
                },                
                {
                    text: "Thao tác",
                    value: "",
                    align: "center",
                    sortable: false,
                    filterable: false
                }
            ],
            package_headers: [
                {
                    text: "STT",
                    value: "index"
                },
                {
                    text: "Mã bill",
                    value: "TransactionCode",
                    filterable: true,
                    quickSearch: true,
                    dataType: configs.DATA_TYPE["String"]
                },
                {
                    text: "Loại hàng",
                    value: "ProductType",
                    filterable: true,
                    values: [],
                    dataType: configs.DATA_TYPE["Enum"]
                },
                {
                    text: "Cân nặng",
                    value: "Weight",
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Khối",
                    value: "Volume",
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Số lượng",
                    value: "RealNumberOfPack",
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Tổng cân",
                    value: "TotalWeight",
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Tổng khối",
                    value: "TotalVolume",
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Phí khác",
                    value: "FeeShipVND",
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Trạng thái",
                    value: "Status",
                    filterable: true,
                    values: configs.PACKAGE_STATUS,
                    dataType: configs.DATA_TYPE["Enum"]
                },
                {
                    text: "Ghi chú",
                    value: "OrderNote",
                    filterable: true,
                    dataType: configs.DATA_TYPE["String"]
                },
                {
                    text: "Thao tác",
                    value: "",
                    align: "center",
                    sortable: false,
                    filterable: false
                }
            ],
            dialogReceipt: false,
            useInternalCode: false,
            package_status: configs.PACKAGE_STATUS,
            receipt_status: configs.EXPORT_RECEIPT_STATUS,
            order_type_list: configs.ORDER_TYPE_LIST,
            pagination: {  ...configs.PAGINATION,  sortBy: ["ID"]  },
            package_pagination: {  ...configs.PAGINATION,  sortBy: ["ID"], itemsPerPage: -1 },
            items_per_page: _.cloneDeep(configs.ITEMS_PER_PAGE),
            viewName: "exportreceipt_list",
            filterConditions: [],
            quickFilterConditions: [],
            requireRules: [v => !!v || "Trường dữ liệu không được để trống."],
            selectedItem: {VNStoreID: 1},
            newTransactionCode: '',
            newTransactionCodeVN: '',
            isApprovePayment: true,
            internalMessage: '',
            isDepositOrder: configs.SYSTEM_TYPE != 'order',
            bigpackageCode: '',
            selectedAddressID: 0,
            selectedCSID: 0,
            selectedCSPhone: '',
            selectedAddress: {},
        };
    },
    watch: {
        pagination: {
            handler: function (val, oldVal) {
                let filterConditions = this.$store.state.commons.filterConditions;
                this.filterConditions = !_.isEmpty(filterConditions) ? filterConditions[this.viewName] : []
                this.filter_data();
            },
            deep: true
        },
        items() {
            if(this.dialogReceipt && this.selectedItem.ID && this.items && this.items.length > 0) {
                let item = _.find(this.items, {'ID': this.selectedItem.ID});
                if(item !== undefined) {
                    this.selectedItem = item;
                }
            }
        },
        product_type_list() {
            let header_item = _.find(this.package_headers, { value: "ProductType" });
            if (header_item) {
                header_item.values = this.product_type_list;
            }
        },
        dialogReceipt() {
            if(!this.dialogReceipt){
                this.$store.commit("orderTransaction/setList", {data: []});
            }
        },
        selectedItem: {
            handler: function (val, oldVal) {
                if(val.ID > 0 && oldVal.ID == 0) {
                    this.getTransactionOfReceipt();
                }
                if(this.selectedItem.UID > 0) {
                    this.$store.dispatch("addressBook/getList", {
                        filter: [{
                            column: "UID",
                            value: this.selectedItem.UID,
                            condition: "equal"
                        }]
                    });
                    let customer = _.find(this.customers, { ID: this.selectedItem.UID });
                    this.selectedCSID= customer.CustomerServiceID;
                    this.isApprovePayment = (customer.IsApprovePayment && (customer.MaxDebt + customer.Wallet) >= 0) || (!customer.IsApprovePayment && customer.Wallet >= 0) || configs.SYSTEM_TYPE == 'order';
                }
            },
            deep: true
        },
        dialogReceipt() {
            this.isApprovePayment = true;
            this.internalMessage = '';
            this.bigpackageCode = '';
            this.selectedAddressID = 0;
            this.selectedAddress = {};
            let first_store = this.vnstores && this.vnstores.length > 0 ? this.vnstores[0] : {ID: 0, Name: ""};
            this.selectedItem.VNStoreID = first_store.ID;
        },
        selectedCSID() {
            let staff = _.find(this.staffs, { ID: this.selectedCSID });
            this.selectedCSPhone= staff.PhoneNumber;
        }
    },
    computed: {
        ...mapState({
            items: state => state.exportReceipt.all.data,
            total_rows: state => state.exportReceipt.all.total,
            currentItem: state => state.exportReceipt.selected,
            summary: state => state.exportReceipt.all.summary,
            loading: state => state.exportReceipt.loading,
            orderTransactions: state => state.orderTransaction.all.data,
            vnstores: state => state.commons.stores,
            chinaStores: state => state.commons.chinaStores,
            configuration: state => state.configuration.selected,
            staffs: state => state.commons.all_users,
            customers: state => _.map(state.customer.master.data, (item) => {
                return {
                    ...item,
                    'FilterName': item.CustomerCode ? item.CustomerCode : item.Username
                }
            }),
            addressBooks: state => state.addressBook.all.data,
        }),
        isMobile: isMobileAgent,
        adminRole() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin;
        },
        tableConfig() {
            let params = {
                pagination: this.pagination,
                filter: [...this.filterConditions],
                quickFilter: [...this.quickFilterConditions]
            };
            if (this.uId > 0) {
                params.filter.push({
                    column: "UID",
                    value: this.uId,
                    condition: "equal"
                });
                params.filter.push({
                    column: "Status",
                    value: 1,
                    condition: "equal"
                });
            }
            return params;
        },
        product_type_list() {
            return this.configuration.ProductTypes ? JSON.parse(this.configuration.ProductTypes) : [];
        }
    },
    methods: {
        filter_data: function () {
            this.$store.dispatch("exportReceipt/getList", this.tableConfig);
            if(this.dialogReceipt) {
                this.getTransactionOfReceipt();
            }
        },
        applyFilter(filterConditions) {
            let sortBy = this.pagination.sortBy
            let sortDesc = this.pagination.sortDesc
            this.pagination = configs.PAGINATION;
            this.pagination.sortBy = sortBy;
            this.pagination.sortDesc = sortDesc;
            this.filterConditions = filterConditions;
            this.filter_data();
        },
        quickFilter(filterConditions) {
            this.quickFilterConditions = filterConditions;
            this.filter_data();
        },
        get_status_color(val, enums) {
            return this.$options.filters.display_color(val, enums);
        },
        getCustomerList() {
            this.$store.dispatch("customer/getCustomerList", {
                is_master: 1,
                fields: ["ID", "Username", "CustomerCode", "Address", "PhoneNumber", "FirstName", "LastName", "CustomerServiceID", "IsApprovePayment", "ChinaStoreID", "ReceivePlaceID", "Wallet", "MaxDebt"]
            });
        },
        getTransactionOfReceipt() {
            if(this.selectedItem.ID > 0) {
                var param={
                    filter: [
                    {
                        column: "ReceiptID",
                        value: this.selectedItem.ID,
                        condition: "equal"
                    }
                    ]
                };
                this.$store.dispatch("orderTransaction/getList", param);
            }            
        },
        onSelectAddress() {
            this.selectedAddress = _.find(this.addressBooks, {ID: this.selectedAddressID});
            this.selectedItem.Receiver = this.selectedAddress.Receiver;
            this.selectedItem.Phone = this.selectedAddress.Phone;
            this.selectedItem.Address = this.selectedAddress.Address;
        },
        onSelectCustomer() {
            let customer = _.find(this.customers, {
                ID: this.selectedItem.UID
            });
            if(customer) {
                this.selectedItem.Username = customer.CustomerCode || customer.Username;
                this.selectedItem.Receiver = `${customer.LastName} ${customer.FirstName}`;
                this.selectedItem.Address = customer.Address;
                this.selectedItem.Phone = customer.PhoneNumber;
                this.selectedCSID= customer.CustomerServiceID;
                if(_.find(this.vnstores, {ID: customer.ReceivePlaceID})) {
                    this.selectedItem.VNStoreID = customer.ReceivePlaceID;
                }
                this.isApprovePayment = (customer.IsApprovePayment && (customer.MaxDebt + customer.Wallet) >= 0) || (!customer.IsApprovePayment && customer.Wallet >= 0) || configs.SYSTEM_TYPE == 'order';
            }
            this.$store.dispatch("addressBook/getList", {
                filter: [{
                    column: "UID",
                    value: this.selectedItem.UID,
                    condition: "equal"
                }]
            });
        },
        appendBigPackage() {
            this.$store.dispatch("bigPackage/receiptExportCode", {
                data: { 'ExportCode': this.bigpackageCode, 'ReceiptID': this.selectedItem.ID }
            }).then((ret) => {
                if(ret.status == 200 && ret.data.length > 0) {
                    let existedItem = _.find(this.orderTransactions, {'TransactionCode': ret.data[0]['TransactionCode']});
                    if(existedItem) {
                        playSound('/vtb-error.mp3');
                        sendErrorNotice('Mã bill đã có trong phiếu xuất kho.');
                        return;
                    }
                    this.orderTransactions.push(...ret.data)
                    this.bigpackageCode = '';
                    refreshResource();
                }
            });
            this.$nextTick(() => this.$refs.bigcode.focus());
            
        },
        appendPackageItem(isInternalCode = false) {
            let existedItem = false;
            if(!isInternalCode) {
                this.newTransactionCode = this.newTransactionCode.trim();
                existedItem = _.find(this.orderTransactions, {'TransactionCode': this.newTransactionCode});
            }
            else {
                this.newTransactionCodeVN = this.newTransactionCodeVN.trim();
                existedItem = _.find(this.orderTransactions, {'TransactionCodeVN': this.newTransactionCodeVN});
            }
            
            if(existedItem) {
                playSound('/vtb-error.mp3');
                sendErrorNotice('Mã bill đã có trong phiếu xuất kho.');
                return;
            }

            var param = {
                filter: [
                {
                    column: "Status",
                    value: configs.PACKAGE_STATUS_ENUM.DA_XUAT_VN,
                    condition: "does_not_equal"
                }
                ]
            };

            if(!isInternalCode) {
                param.filter.push({
                    column: "TransactionCode",
                    value: this.newTransactionCode,
                    condition: "s_equal"
                })
            }
            else { 
                param.filter.push({
                    column: "TransactionCodeVN",
                    value: this.newTransactionCodeVN,
                    condition: "s_equal"
                })
            }

            request_list('order_transactions', ret => {
                if(!isInternalCode && ret.data.length > 1) {
                    this.internalMessage = `Mã tách ${this.newTransactionCode} gồm ${ret.data.length} kiện.` ;
                }
                else if(!isInternalCode && ret.data.length <= 1) {
                    this.internalMessage = ''; 
                    this.useInternalCode = false;
                }
                if(ret.data.length == 1) {
                    if(!ret.data[0].ChinaWeight && !ret.data[0].Weight) {
                        playSound('/vtb-error.mp3');
                        sendErrorNotice('Mã bill chưa nhập cân nặng.');
                        return;
                    }
                    if(ret.data[0].ProductType == null || ret.data[0].ProductType == -1) {
                        playSound('/vtb-error.mp3');
                        sendErrorNotice('Chưa xác định loại hàng.');
                        return;
                    }
                    if(ret.data[0].ReceiptID > 0) {
                        playSound('/vtb-error.mp3');
                        sendErrorNotice(`Mã bill đã tồn tại trong phiếu ${ret.data[0].ReceiptID}.`);
                        return;
                    }
                    if(this.selectedItem.ID > 0) {
                        this.$store.dispatch("orderTransaction/setDetail", {
                            data: { 'ReceiptID': this.selectedItem.ID, 'Status': configs.PACKAGE_STATUS_ENUM.DA_VE_VN, 'ID': ret.data[0].ID },
                            id: ret.data[0].ID
                        });
                        this.saveItem();
                        this.$nextTick(() => this.$refs.trancode.focus());
                    }
                    sendSuccessNotice(`Đã thêm mã bill #${ret.data[0].TransactionCode} vào phiếu xuất.`)
                    this.orderTransactions.push(ret.data[0])
                    this.newTransactionCode = '';
                }
                else if(ret.data.length > 1) {
                    this.useInternalCode = true;
                }
                else {
                    playSound('/vtb-error.mp3');
                    sendErrorNotice('Mã bill không tồn tại hoặc chưa nhập kho.')
                }
            }, Vue.http, param, this.$store.state.authentication.token);
        },
        deletePackageItem(transaction) {
            if(this.selectedItem.ID > 0) {
                this.$store.dispatch("orderTransaction/setDetail", {
                    data: { 'ReceiptID': 0, 'ID': transaction.ID },
                    id: transaction.ID
                });
                this.saveItem();
            }
            else {
                this.$store.commit('orderTransaction/removeFromList', transaction)
            }
            this.$nextTick(() => this.$refs.trancode.focus());
        },
        createData() {
            this.selectedItem = {
                ID: 0,
                UID: 0,
                Status: 0
            };
            this.$store.commit("orderTransaction/setList", {data: [], total: 0});
            this.dialogReceipt = true;
        },
        gotoDetail(item) {
            this.selectedItem = item;
            this.getTransactionOfReceipt();
            this.dialogReceipt = true;
        },
        printDetail() {
            const frame_printing = configs.SYSTEM_TYPE == 'order' ?  print_export_receipt_order({...this.selectedItem, Voter: this.$store.state.authentication.user.fullname}, this.orderTransactions, true) : print_export_receipt_deposit({...this.selectedItem, Voter: this.$store.state.authentication.user.fullname}, this.orderTransactions, true);
            var doc = document.getElementById('print_frame').contentWindow.document;
            doc.open();
            doc.write(frame_printing);
            doc.close();
            setTimeout(function(){ window.frames["print_frame"].window.print(); }, 1000);
        },
        deleteItem(id) {
            window.getApp.$emit('REQUEST_DELETE_DATA', id);
        },
        exportItem(){
            this.selectedItem.Action = 'export';
            this.selectedItem.Status = 1;
            this.selectedItem.LockedReceipt = 1;
            this.saveItem();
        },
        lockReceipt() {
            this.selectedItem.LockedReceipt = 1;
            this.saveItem();
        },
        saveItem() {
            if(!this.selectedItem.UID) {
                sendErrorNotice('Hãy chọn thông tin khách hàng trước khi lưu.')
                return;
            }
            this.selectedItem.OrderType = configs.SYSTEM_TYPE == 'order' ? configs.ORDER_TYPE.order : configs.ORDER_TYPE.deposit;
            this.$store.dispatch("exportReceipt/setDetail", {
                data: {...this.selectedItem, NumberOfPack: _.sumBy(this.orderTransactions, 'RealNumberOfPack'), Packages: !this.selectedItem.ID ? _.map(this.orderTransactions, (item) => {return item.ID}) : null },
                id: this.selectedItem.ID
            }).then((ret) => {
                if(this.selectedItem.ID == 0) {
                    this.selectedItem = {...this.currentItem};
                }
            });
        }
    },
    mounted() {
        this.$store.dispatch("configuration/getDetail", 1);
        this.getCustomerList();
        this.$store.dispatch("commons/getAllUsers");
        this.$store.dispatch("commons/getAllStores");
        this.$store.dispatch("commons/getAllChinaStores");
        this.$root.$emit('REQUEST_ROOT_OPTION', {});
        this.$root.$on("REQUEST_CREATE_DATA", this.createData);
        this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
        this.$root.$on("CONFIRMED_REMOVE_DATA", id => {
            this.$store.dispatch("exportReceipt/removeDetail", id);
            setTimeout(this.filter_data, 200);
        });
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_CREATE_DATA", this.createData);
        this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
        this.$root.$off("CONFIRMED_REMOVE_DATA");
    }
};
</script>
